<template>
  <div class="border rounded mt-4">
    <div
      class="d-flex align-items-center justify-content-between bg-light rounded py-2 px-5"
    >
      <h5 class="text-center font-bold">اطلاعات پرداخت (چک)</h5>
      <div>
        <button type="button" class="btn btn-success" v-b-modal.new-cheque>
          + افزودن چک
        </button>
      </div>
    </div>
    <div v-if="chequeItems && chequeItems.length" class="container pb-3">
      <div v-for="(item, index) in chequeItems" :key="index" class="pt-3">
        <div
          class="d-flex align-items-center justify-content-between bg-light rounded py-2 px-5"
        >
          <h5 class="text-center font-bold">{{ `چک شماره ${index + 1}` }}</h5>
          <div>
            <button
              id="edit-cheque"
              type="button"
              class="btn btn-info fontsize-small p-0 ml-3 px-2"
              @click="openUpdateModal(item.id)"
            >
              <i class="feather feather-edit" style="line-height: unset"></i>
              <b-tooltip target="edit-cheque" triggers="hover">
                ویرایش
              </b-tooltip>
            </button>
            <button
              id="delete-cheque"
              type="button"
              class="btn btn-danger p-0 px-2"
              @click="deleteCheque(item.id)"
            >
              <i
                class="feather feather-trash-2 fontsize-medium"
                style="line-height: unset"
              ></i>
              <b-tooltip target="delete-cheque" triggers="hover">
                حذف
              </b-tooltip>
            </button>
          </div>
        </div>
        <div class="container mx-auto row p-3 border">
          <div class="col-md-4 d-flex mt-2">
            <h6 class="font-bold">نام بانک :</h6>
            <h6 class="pr-1">
              {{ item.bank_name }}
            </h6>
          </div>
          <div class="col-md-4 d-flex mt-2">
            <h6 class="font-bold">شماره شناسه چک :</h6>
            <h6 class="pr-1">
              {{ item.cheque_number }}
            </h6>
          </div>
          <div class="col-md-4 d-flex mt-2">
            <h6 class="font-bold">وضعیت وصول :</h6>
            <h6 class="pr-1">{{ item.status_label }}</h6>
          </div>
          <div class="col-md-4 d-flex mt-2">
            <h6 class="font-bold">مبلغ :</h6>
            <h6 class="pr-1">
              {{ item.amount | price }}
            </h6>
          </div>
          <div class="col-md-4 d-flex mt-2">
            <h6 class="font-bold">تاریخ ثبت :</h6>
            <h6 class="pr-1">
              {{ createdAtDate(item.created_at) }}
            </h6>
          </div>
        </div>
        <UpdateCheque @after-update="afterUpdate" :chequeId="item.id" />
      </div>
    </div>
    <AddCheque @update-cheque-items="updateChequeItems" />
  </div>
</template>

<script>
import OrderCheques from "../../../../admin/components/order/OrderCheques/OrderChequesImpl";
export default {
  extends: OrderCheques,
};
</script>

<style scoped></style>
