<template>
  <b-modal id="marketers-modal" :title="mode === 'edit' ? 'ویرایش کد تخفیف' : 'ثبت کد تخفیف' " :ok-disabled="disabled" :cancel-disabled="disabled" @ok="submit" class="in-form-modal" ok-title="ثبت"
           cancel-title="لغو" size="lg">
    <form @submit.prevent="submit" >
      <div class="row">
        <b-form-group class="col-md-6" >
          <label for="title"> نام <i class="text-danger">*</i></label>
          <b-form-input id="name" ref="name" :value="marketer.name"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" >
          <label for="code"> نام خانوادگی <i class="text-danger">*</i></label>
          <b-form-input id="code" ref="last_name" :value="marketer.last_name"></b-form-input>
        </b-form-group>
       
        <b-form-group class="col-md-6" >
          <label for="usage-limit">  موبایل <i class="text-danger">*</i></label>
          <b-form-input ref="mobile" id="usage-limit" :value="marketer.mobile"></b-form-input>
        </b-form-group>
        <b-form-group class="col-md-6" >
          <label for="usage-limit">  وضعیت <i class="text-danger">*</i></label>
          <input type="checkbox" v-model="status">
        </b-form-group>

      </div>
    </form>
  </b-modal>
</template>
<script>
import marketersFormImpl from '@@/core/components/marketers/marketersForm/marketersFormImpl'
export default {
  extends: marketersFormImpl
}
</script>
