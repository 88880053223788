<template>
    <main class="mt-5">
      <span id="add-btn">
        <router-link
            :disabled="hasPermission('write_customer') ? false : true"
            to="/admin/customers/create"
            class="btn btn-success"
        >مشتری جدید</router-link
        >
      </span>
      <b-tooltip
          v-if="!hasPermission('write_customer')"
          target="add-btn"
          triggers="hover"
      >
        شما به این بخش دسترسی ندارید
      </b-tooltip>
      <div class="card mt-3">
        <div class="card-header  border-0">
          <h5 class="card-title font-bold">مشتریان</h5>
          <div style="flex:1"></div>
          <b-button
              v-b-toggle.filter
              :variant="hasFilter() ? 'secondary' : 'primary'"
          >
            <span class="fe fe-search pt-1" style="font-size: 17px;"></span>
          </b-button>
        </div>
        <b-collapse id="filter" class="mt-2 mx-5">
          <b-card>
            <form @submit.prevent="filterItem()">
              <div class="row mt-5">
                <b-form-group class="col-md-3">
                  <b-form-input
                      :disabled="disabled"
                      placeholder="نام "
                      v-model="filter.first_name"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-md-3">
                  <b-form-input
                      :disabled="disabled"
                      placeholder="نام خانوادگی"
                      v-model="filter.last_name"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-md-3">
                  <b-form-input
                      :disabled="disabled"
                      placeholder="شماره همراه"
                      v-model="filter.mobile"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-md-3">
                  <b-form-input
                      :disabled="disabled"
                      placeholder="ایمیل"
                      v-model="filter.email"
                  >
                  </b-form-input>
                </b-form-group>
                <b-form-group class="col-md-3">
                  <div class="d-flex">
                    <label @click="() => filter.has_deposits = filter.has_deposits ? 0 : 1"
                           class="ml-2">شارژ کیف پول توسط مشتری</label>
                    <b-form-checkbox :unchecked-value="0"
                                     :disabled="disabled"
                                     :value="1"
                                     v-model="filter.has_deposits"
                    />
                  </div>
                </b-form-group>
                <b-form-group class="col-md-3">
                  <div class="d-flex">
                    <label @click="() => filter.has_transactions = filter.has_transactions ? 0 : 1"
                           class="ml-2">تراکنش کیف پول دارد</label>
                    <b-form-checkbox :unchecked-value="0"
                                     :disabled="disabled"
                                     :value="1"
                                     v-model="filter.has_transactions"
                    />
                  </div>
                </b-form-group>
              </div>
              <div class="text-center mt-3">
                <button
                    :disabled="disabled"
                    type="submit"
                    class="btn btn-primary px-5"
                >
                  جستجو
                </button>
                <button
                    :disabled="disabled"
                    @click="resetFilter"
                    class="btn btn-warning px-5 mr-3"
                >
                  برداشتن فیلتر
                </button>
                <button
                    :disabled="disabled"
                    @click="$root.$emit('bv::toggle::collapse', 'filter')"
                    class="btn btn-secondary px-5 mr-3"
                >
                  بستن
                </button>
                <ExcelDownload class="mr-3" :url="url" />
              </div>
            </form>
          </b-card>
        </b-collapse>
        <div class="card-body">
          <div>
            <b-table responsive hover bordered :items="items.data" :fields="fields">
              <template v-slot:cell(gender)="data">
                <span v-if="data.item.gender == 'male'">مرد</span>
                <span v-else-if="data.item.gender == 'female'">زن</span>
              </template>
              <template v-slot:cell(created_at)="data">
                {{ data.item.created_at | persianDate }}
              </template>
              <template v-slot:cell(edit)="data">
                <span :id="'edit-btn-' + data.item.id">
                  <router-link
                      :disabled="!hasPermission('modify_customer') ? true : false"
                      :to="{ name: 'customer.edit', params: { id: data.item.id } }"
                      class="action-btns1  pt-1 px-2"
                  >
                    <i class="feather feather-edit  text-primary"></i>
                  </router-link>
                </span>
                <b-tooltip
                    v-if="!hasPermission('modify_customer')"
                    :target="'edit-btn-' + data.item.id"
                    triggers="hover"
                >
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </template>
              <template v-slot:cell(show)="data">
                <router-link
                    :to="{ name: 'customer.show', params: { id: data.item.id } }"
                    class="action-btns1  pt-1 px-2"
                >
                  <i class="fe fe-eye  text-info"></i>
                </router-link>
              </template>
              <template v-slot:cell(delete)="data">
                <span :id="'delete-btn-' + data.item.id">
                  <a
                      :disabled="!hasPermission('delete_customer') ? true : false"
                      class="action-btns1  pt-1 px-2 "
                      @click="deleteItem(data.item.id)"
                  >
                    <i class="feather feather-trash-2 text-danger"></i>
                  </a>
                </span>
                <b-tooltip
                    v-if="!hasPermission('delete_customer')"
                    :target="'delete-btn-' + data.item.id"
                    triggers="hover"
                >
                  شما به این بخش دسترسی ندارید
                </b-tooltip>
              </template>
              <template v-slot:cell(status)="data">
              <span
                v-if="data.item.status == 1"
                class="badge badge-success status"
                >فعال </span
              >
              <span
              @click="updateCustomerStatus(data)"
                v-else-if="data.item.status == 0"
                class="badge badge-danger status"
                >غیر فعال</span
              >
            </template>
            </b-table>
            
            <Loading v-if="$root.isEmptyObject(items)" />
            <pagination
                v-else-if="items.data != ''"
                :disabled="disabled"
                :limit="2"
                :data="items"
                @pagination-change-page="changePage"
            >
            </pagination>
            <div v-else class="alert alert-danger">
              موردی یافت نشد
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  <script>
  import CustomersImpl from '@@/core/pages/customer/Customers/CustomersImpl'
  import ExcelDownload from "@@/core/components/excelDownload/ExcelDownload";
  export default {
    components: {ExcelDownload},
    extends: CustomersImpl
  }
  </script>
  