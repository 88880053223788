<template>
  <b-modal id="new-cheque" hide-footer title="افزودن چک جدید" size="lg">
    <form @submit.prevent="newChequeItem">
      <b-form-group>
        <div class="d-flex justify-content-between">
          <label> نام بانک : <i class="text-danger">*</i></label>
        </div>
        <b-form-input v-model="bankName" type="text"></b-form-input>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between">
          <label> شماره شناسه چک : <i class="text-danger">*</i></label>
        </div>
        <b-form-input v-model="chequeNumber" type="number"></b-form-input>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between">
          <label> قیمت : <i class="text-danger">*</i></label>
        </div>
        <b-form-input v-model="amount" type="number"></b-form-input>
      </b-form-group>
      <b-form-group>
        <div class="d-flex justify-content-between">
          <label> وضعیت وصول : <i class="text-danger">*</i></label>
        </div>
        <div>
          <b-form-select v-model="selected" :options="options"></b-form-select>
        </div>
      </b-form-group>
      <div class="text-center mt-3">
        <button class="btn btn-success">ثبت</button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import { BModal, BFormGroup, BFormInput, BFormSelect } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      options: [
        { value: "returned", text: "برگشت خورده" },
        { value: "passed", text: "پرداخت شده" },
        { value: "not_passed", text: "پرداخت نشده" },
      ],
      disabled: false,
      bankName: "",
      chequeNumber: 0,
      amount: 0,
      selected: "",
    };
  },
  methods: {
    async newChequeItem() {
      this.disabled = true;
      try {
        let formData = new FormData();
        formData.append("bank_name", this.bankName);
        formData.append("cheque_number", this.chequeNumber);
        formData.append("amount", this.amount);
        formData.append("status", this.selected);
        formData.append("order_id", this.$route.params.id);
        const response = await this.$axios.post("/admin/cheques", formData);
        this.$emit("update-cheque-items", response.data.data.cheque);
        this.$root.notify(response.data.message, "success");
        this.$root.$emit("bv::hide::modal", "new-cheque");
      } catch (error) {
        this.$root.notify(error);
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
