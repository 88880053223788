import { cartesian } from "@@/core/libraries/VueMethods";
import variety from "@@/core/components/product/classes/variety";
import dayjs from "dayjs";

export default class productModelToData {
  /**
   * @param {{id: number, title: string, short_description: string, description: string, unit_price: number,
   * purchase_price: number, discount_type: string, discount: number, SKU: string, barcode: string, brand_id: number,
   * unit_id: number, meta_description: string, meta_title: string, low_stock_quantity_warning: number,
   * show_quantity: number, status: string, approved_at: string, images: array, price: number, tags: array,size_charts: array,
   * varieties: [{
   *   id: number,
   *   price: number,
   *   SKU: string,
   *   barcode: string
   *   purchase_price: number,
   *   product_id: number,
   *   discount_type: string,
   *   discount: number,
   *   quantity: number,
   *   color_id: number,
   *   images: array,
   *   attributes: array,
   * }],
   * categories: [
   *   {
   *     id: number,
   *   }
   * ],
   * specifications: [],
   *
   * }} model
   * @param {
   * {
   * productInformation: {
   *     title: '',
   *     categories: [],
   *     brand: null,
   *     unit: null,
   *     barcode: '',
   *     sku: '',
   *     tags: [],
   *     quantity: ''
   *   },
   *   productPricing: {
   *     unitPrice: '',
   *     purchasePrice: '',
   *     discountType: 'none',
   *     discount: '',
   *   },
   *   productDescription: {
   *     shortDescription: '',
   *     description: '',
   *   },
   *   productImages: [],
   *   productSizeCharts: [
   *     {
   *       name: String,
   *       type_id: Number,
   *       charts: [
   *         ['', '', ''],
   *         ['', '', ''],
   *         ['', '', ''],
   *       ]
   *     }
   *   ],
   *   selectedSpecs: [],
   *   productVarieties: {
   *     colors: [],
   *     attributes: [],
   *     varieties: [],
   *     selected_attribute_values: [],
   *   },
   *   productPublishing: {
   *     status: null,
   *     publishedAt: '',
   *     scheduling: true
   *   },
   *   productShow: {
   *     showQuantity: false
   *   },
   *   productSeo: {
   *     metaTitle: '',
   *     metaDescription: ''
   *   },
   *   productOther: {
   *     lowStockQuantityWarning: null
   *   }
   * }
   * } $data
   */
  constructor(model, $data) {
    $data.productGifts = this.getGifts(model.gifts);
    $data.productInformation.barcode = model.barcode;
    $data.productInformation.title = model.title;
    $data.productInformation.sku = model.SKU;
    $data.productInformation.unit = model.unit_id;
    if (this.hasFakeVariety(model.varieties)) {
      // Has Fake variety
      $data.productInformation.quantity = model.varieties[0].quantity;
    }
    $data.productInformation.tags = model.tags.map((tag) => tag.name);
    $data.productInformation.brand = model.brand_id;
    $data.productInformation.categories = model.categories.map((cat) => cat.id);
    $data.productVideo.video=model.video==[]?null:model.video.url
    $data.editProductVideo.video=model.video==[]?null:model.video
    $data.productVideo.cover=model.video_cover==[]?null:model.video_cover.url
    $data.editProductVideo.cover=model.video_cover==[]?null:model.video_cover
    $data.productPricing.discountType =
      model.discount_type || $data.productPricing.discountType;
    $data.productPricing.discount =
      model.discount || $data.productPricing.discount;
    $data.productPricing.discountUntil = dayjs(model.discount_until)
      .clone()
      .locale("en")
      .format("YYYY/MM/DD HH:mm");
    $data.productPricing.purchasePrice =
      model.purchase_price || $data.productPricing.purchasePrice;
    $data.productPricing.unitPrice =
      model.unit_price || $data.productPricing.unitPrice;

    if (model.published_at) {
      $data.productPublishing.publishedAt = model.published_at;
    }

    const now = dayjs();
    $data.productPublishing.scheduling =
      dayjs(model.published_at).unix() > now.unix();
    $data.productPublishing.status = model.status;

    $data.productDescription.description = model.description;
    $data.productDescription.shortDescription = model.short_description;

    $data.productOther.lowStockQuantityWarning =
      model.low_stock_quantity_warning;

    $data.productSeo.metaTitle = model.meta_title;
    $data.productSeo.metaDescription = model.meta_description;
    $data.productSeo.slug = model.slug;

    $data.productImages = model.images.map((img) => {
      return {
        url: img.url,
        key: img.id,
      };
    });

    $data.selectedSpecs = [];
    model.specifications.forEach((spec) => {
      switch (spec.type) {
        case "multi_select":
          $data.selectedSpecs[spec.id] = spec.pivot.specification_values.map(
            (sv) => sv.id
          );
          break;
        case "select":
          $data.selectedSpecs[spec.id] = spec.pivot.specification_value.id;
          break;
        case "text":
          $data.selectedSpecs[spec.id] = spec.pivot.value;
          break;
      }
    });

    if (model.size_charts && model.size_charts.length) {
      model.size_charts.forEach((sizeChart) => {
        $data.productSizeCharts.push({
          title: sizeChart.title,
          chart: JSON.parse(sizeChart.chart),
          type_id: sizeChart.type_id,
        });
      });
    }

    $data.productShow.showQuantity = Boolean(model.show_quantity);
    $data.productShow.chargeable = Boolean(model.chargeable);

    /*
     * Make varieties
     */
    let colors = [];
    let attributes = [];
    let selected_attribute_values = [];
    let varieties = [];
    if (!this.hasFakeVariety(model.varieties)) {
      model.varieties.forEach((variety) => {
        if (variety.color_id && !colors.includes(variety.color_id)) {
          colors.push(variety.color_id);
        }
        variety.attributes.forEach((attr) => {
          if (!attributes.includes(attr.id)) {
            attributes.push(attr.id);
          }
          switch (attr.type) {
            case "select":
              if (!selected_attribute_values[attr.id]) {
                selected_attribute_values[attr.id] = [];
              }
              if (
                !selected_attribute_values[attr.id].includes(
                  attr.pivot.attribute_value_id
                )
              ) {
                selected_attribute_values[attr.id].push(
                  attr.pivot.attribute_value_id
                );
              }
              break;
            case "text":
              if (!selected_attribute_values[attr.id]) {
                selected_attribute_values[attr.id] = [];
              }
              if (
                !selected_attribute_values[attr.id].includes(attr.pivot.value)
              ) {
                selected_attribute_values[attr.id].push(attr.pivot.value);
              }
              break;
          }
        });
      });
      let varietyTs = productModelToData.getVarietyTs(
        colors,
        attributes,
        selected_attribute_values,
        $data.attributes
      );
      let varietiesTemplate = productModelToData.makeCombinations(varietyTs);
      productModelToData.makeVarieties(
        varieties,
        varietiesTemplate,
        model.unit_price,
        varietyTs
      );
    }
    $data.productVarieties.colors = colors;
    $data.productVarieties.selected_attribute_values = selected_attribute_values;
    $data.productVarieties.attributes = attributes;
    $data.productVarieties.varieties = this.assignDataToVarieties(
      varieties,
      model
    );
  }

  assignDataToVarieties(varieties, model) {
    varieties.forEach((variety) => {
      let attributes = [];
      let colorId = null;
      variety.ts.forEach((tss) => {
        if (tss.type === "color") {
          colorId = tss.values[tss.value];
        } else {
          attributes.push({
            id: tss.model.id,
            value: tss.values[tss.value],
          });
        }
      });
      let found = false;
      model.varieties.forEach((_variety) => {
        if (_variety.color_id != colorId) {
          return;
        }
        let hasAllAttributes = true;
        _variety.attributes.forEach((attr) => {
          if (!hasAllAttributes) {
            return;
          }
          let myAttribute = attributes.find((at) => at.id == attr.id);
          if (!myAttribute) {
            hasAllAttributes = false;
          }

          switch (attr.type) {
            case "select":
              if (attr.pivot.attribute_value_id != myAttribute.value.id) {
                hasAllAttributes = false;
              }
              break;
            case "text":
              if (attr.pivot.value != myAttribute.value) {
                hasAllAttributes = false;
              }
              break;
          }
        });
        if (hasAllAttributes) {
          found = _variety;
        }
      });
      if (found) {
        // نام گذاری دوباره بعضی از ویژگی های تنوع
        found.purchasePrice = found.purchase_price;
        found.discountType = found.discount_type;
        found.discountUntil = found.discount_until;
        found.productGifts = this.getGifts(found.gifts);
        delete found.purchase_price;
        delete found.discount_type;
        delete found.discount_until;
        variety.data = found;
      }
    });
    varieties.forEach((v) => {
      v.data.images = v.data.images.map((img) => {
        return {
          url: img.url,
          key: img.id,
        };
      });
    });
    return varieties;
  }

  static getVarietyTs(
    colors,
    attributes,
    selected_attribute_values,
    allAttributes
  ) {
    attributes = attributes.map((atId) => {
      return productModelToData.getAttribute(atId, allAttributes);
    });
    let ts = [];
    if (colors.length) {
      ts.push({
        type: "color",
        model: { id: "color" },
        values: colors,
      });
    }
    attributes.forEach((at) => {
      let values = selected_attribute_values[at.id];
      if (typeof values === "undefined" || values.length === 0) {
        return;
      }
      if (at.type === "select") {
        values = at.values.filter((item) => {
          return selected_attribute_values[at.id].includes(item.id);
        });
      } else {
        values = selected_attribute_values[at.id];
      }

      ts.push({
        type: "attribute",
        model: at,
        values: values,
      });
    });

    return ts;
  }

  static makeCombinations(ts) {
    let possibleValues = [];
    ts.forEach((t) => {
      let p = [];
      for (let i = 0; i < t.values.length; i++) {
        p.push(i);
      }
      possibleValues.push(p);
    });
    if (possibleValues.length === 0) {
      return [];
    }
    let cartesian = productModelToData.cartesian(...possibleValues);
    console.log(cartesian);
    if (!Array.isArray(cartesian[0])) {
      cartesian = cartesian.map((c) => {
        return [c];
      });
    }
    return cartesian;
  }

  static makeVarieties(
    varieties,
    varietiesTemplate,
    defaultPrice,
    getVarietyTs
  ) {
    varieties.forEach((v) => {
      v.present = false;
    });
    varietiesTemplate.forEach((i, _index) => {
      if (!varieties[_index]) {
        varieties.push(
          new variety({
            price: defaultPrice,
          })
        );
      }
      varieties[_index].ts = getVarietyTs.map((vt, __index) => {
        return {
          value: i[__index],
          ...vt,
        };
      });
      varieties[_index].present = true;
      varieties[_index].unique_key = productModelToData.getUniqueKey(
        i,
        varieties[_index],
        getVarietyTs
      );
    });
  }

  static getUniqueKey(template, variety, getVarietyTs) {
    console.log(template, variety, getVarietyTs);
    let uniqueKey = "";
    getVarietyTs.forEach((vt, __index) => {
      let obj = vt.values[template[__index]];
      uniqueKey += typeof obj === "object" ? obj.id : obj;
    });

    return uniqueKey;
  }

  static cartesian(a, b, ...c) {
    const f = (a, b) =>
      [].concat(...a.map((d) => b.map((e) => [].concat(d, e))));
    if (b) {
      return productModelToData.cartesian(f(a, b), ...c);
    } else {
      return Array.isArray(a) ? a : [[a]];
    }
  }

  hasFakeVariety(varieties) {
    return (
      varieties.length === 1 &&
      varieties[0].color_id == null &&
      varieties[0].attributes.length === 0
    );
  }

  getGifts(gifts) {
    if (!gifts) {
      return {
        gifts: [],
        shouldMerge: false,
      };
    }
    return {
      gifts: gifts.map((g) => g.id),
      shouldMerge:
        gifts.filter((g) => g.pivot.should_merge).length == gifts.length,
    };
  }

  static getAttribute(id, attributes) {
    return attributes.find((item) => {
      return item.id == id;
    });
  }
}
