<template>
  <div id="productVideo" class="card mt-3">
    <div class="card-header border-0">
      <h5 class="card-title">ویدیو محصول</h5>
    </div>
    <div class="card-body">
      <InputGroup label="کاور ویدیو">
        <div class="custom-file">
          <input
            type="file"
            class="custom-file-input"
            id="videoCoverFileInput"
            accept="image/*"
            @change="videoCoverChange"
          />
          <label class="custom-file-label" for="videoCoverFileInput">{{
            videoCoverName
          }}</label>
        </div>
        <input />
      </InputGroup>
      <InputGroup label=" ویدیو">
        <input
          class="custom-file-input"
          id="videoFileInput"
          type="file"
          accept="video/*"
          @change="videoChange"
        />
        <label class="custom-file-label" for="videoFileInput">{{
          videoName
        }}</label>
      </InputGroup>
      <div class="previewBox">
        <div class="previewVideo" v-if="realValue.cover">
          <img
            class="videoContent"
            :src="realValue.cover"
            alt="video cover"
          />
          <i @click="realValue.cover = null" class="fa fa-trash"></i>
        </div>
        <div v-if="realValue.video" class="previewVideo">
          <video class="videoContent" width="320" height="240" controls>
            <source
              :src="realValue.video"
              type="video/mp4"
            />
          </video>
          <i @click="realValue.video = null" class="fa fa-trash"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductInformationImpl from "@@/core/components/product/ProductVideo/ProductVideoImpl";

export default {
  extends: ProductInformationImpl,
};
</script>
<style>
#productVideo {
  width: 100%;
  background: white;
  border-radius: 15px;
  padding: 10px 20px;
}
.previewVideo {
  width: 50%;
  position: relative;
}
@media (max-width: 768px) {
  .previewVideo {
    width: 100%;
  }
}
.previewBox {
  display: flex;
  gap: 10px;
}
.previewBox i {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 8px;
  background: red;
  color: white;
  border-radius: 8px;
}
</style>
