import {BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BModal} from "bootstrap-vue";

export default {
  components: {
    BModal, BFormGroup, BFormInput, BFormSelect, BFormSelectOption
  },
  props: {
    marketer: Object,
    mode: {
      default: 'create',
      type: String
    },
    url: String,
  },
  data() {
    return {
      status:false,
      disabled: false,
      startDate: '',
      endDate: '',
    }
  },
  methods: {
    async submit(e) {
      e.preventDefault()
      this.disabled = true
      try {
        const response = await this.$axios.post(this.url, {
          name: this.$refs.name.$el.value,
          last_name: this.$refs.last_name.$el.value,
          mobile: this.$refs.mobile.$el.value,
          status: this.status,
          _method: this.mode == 'edit' ? 'PUT' : 'post'
        });
        this.$root.notify(response.data.message, 'success')
        if (this.mode == 'edit') {
          this.$root.$set(this.$parent._data.Items, this.marketer.index, response.data.data.marketer);
        } else {
          this.$parent._data.Items.unshift(response.data.data.marketer)
        }
        this.$root.$emit('bv::hide::modal', 'marketers-modal')
      } catch (error) {
        this.$root.notify(error)
      }
      this.disabled = false
    },
  }
}
