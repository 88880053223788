<template>
    <main class="mt-5">
        <span id="add-btn">
            <button @click="newmarketers"
                v-b-modal.marketers-modal class="btn btn-success">بازاریاب جدید</button>
        </span>
       
        <div class="card mt-3">
         

            <div class="card-body">
                <div>
                    <b-table responsive hover bordered :items="Items" :fields="fields">
                        <template v-slot:cell(status)="data">
                            <span v-if="data.item.status==1" class="badge badge-success">
                                فعال
                            </span>
                            <span v-else class="badge badge-warning">
                                غیرفعال
                            </span>
                        </template>
                        <template v-slot:cell(edit)="data">
                            <span :id="'edit-btn-' + data.item.id">
                                <a @click="editItem(data.item.id,data.index)" 
                                class="action-btns1  pt-1 px-2">
                                <i class="feather feather-edit  text-primary"></i>
                            </a>
                            </span>
                         
                        </template>
                        <template v-slot:cell(delete)="data">
                            <span :id="'delete-btn-' + data.item.id">
                                <a  class="action-btns1  pt-1 px-2 " @click="DeleteItem(data.item.id)">
                                    <i class="feather feather-trash-2 text-danger"></i>
                                </a>
                              </span>
                         
                        </template>
                    </b-table>
                    <Loading v-if="$root.isEmptyObject(Items)" />

                    <div v-else-if="items.length == 0" class="alert alert-danger">
                        موردی یافت نشد
                    </div>
                </div>
            </div>
        </div>
        <marketersModal :mode="mode" :marketer="marketer" :url="submit_url" />
    </main>
</template>
<script>
import marketersImpl from '@@/core/pages/marketers/marketersImpl'
export default {
    extends: marketersImpl
}
</script>