import { render, staticRenderFns } from "./OrderDetail.vue?vue&type=template&id=353f23b8&scoped=true&"
import script from "./OrderDetail.vue?vue&type=script&lang=js&"
export * from "./OrderDetail.vue?vue&type=script&lang=js&"
import style0 from "@@/core/pages/order/OrderDetail/OrderDetailStyle.css?vue&type=style&index=0&id=353f23b8&prod&scoped=true&lang=css&"
import style1 from "@@/core/pages/order/OrderDetail/OrderDetailStylePublic.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "353f23b8",
  null
  
)

export default component.exports