import AddCheque from "../../../../admin/components/order/AddCheque/AddCheque.vue";
import UpdateCheque from "../../../../admin/components/order/UpdateCheque/UpdateCheque.vue";

import {
  BFormGroup,
  BFormInput,
  BModal,
  BTable,
  BTooltip,
  VBModal,
} from "bootstrap-vue";

export default {
  name: "OrderCheque",
  components: {
    BTooltip,
    BModal,
    AddCheque,
    UpdateCheque,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      disabled: false,
      chequeItems: [],
    };
  },
  props: {
    order: Object,
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadChequeItems(this.$route.params.id);
    }
  },
  methods: {
    async loadChequeItems(id) {
      try {
        const response = await this.$axios.get(`/admin/cheques-orders/${id}`);
        this.chequeItems = response.data.data.cheques.data;
      } catch (error) {
        this.$root.notify(error);
      }
    },
    updateChequeItems(item) {
      this.chequeItems.push(item);
    },
    afterUpdate(item) {
      let foundedIndex = this.chequeItems.findIndex((i) => i.id == item.id);
      this.chequeItems[foundedIndex] = item;
      this.$forceUpdate();
    },
    openUpdateModal(id) {
      this.$root.$emit("bv::show::modal", `update-cheque-${id}`);
    },
    deleteCheque(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            this.$axios
              .delete(`/admin/cheques/${id}`)
              .then((response) => {
                this.chequeItems = this.chequeItems.filter(
                  (item) => item.id != id
                );
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        });
    },
    createdAtDate(date) {
      return this.$options.filters.Date(date);
    },
  },
};
