import InputGroup from "@@/core/components/product/shared/InputGroup/InputGroup";

export default {
  name: "ProductVideo",
  components: {
    InputGroup,
  },
  props: {
    value: Object,
    mode: String,
  },
  methods: {
    videoCoverChange(e) {
      if (e.target.files[0]) {
        this.videoCoverName = e.target.files[0].name;
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        let fakeThis = this;
        reader.onload = function() {
          fakeThis.realValue.cover = reader.result;
          if(this.mode != "create") {
          fakeThis.realValue.new_cover = reader.result;
          }
        };
      } else {
        this.videoCoverName = "انتخاب ویدیو";
        this.value.cover = this.mode == "create" ? null : this.lastImage;
      }
    },
    videoChange(e) {
      if (e.target.files[0]) {
        this.videoName = e.target.files[0].name;
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        let fakeThis = this;
        reader.onload = function() {
          fakeThis.realValue.video = reader.result;
          if(this.mode != "create") {
            fakeThis.realValue.new_video = reader.result;
            }
        };
      } else {
        this.videoName = "انتخاب ویدیو";
        this.value.video = this.mode == "create" ? null : this.lastVideo;
      }
    },
  },
  data() {
    return {
      videoName: "انتخاب ویدیو",
      videoCoverName: "انتخاب کاور ویدیو",
      realValue: this.value,
      lastImage: this.value.cover,
      lastVideo: this.value.video,
    };
  },
  watch: {
    value: function(newVal) {
      this.realValue = newVal;
    },
    realValue: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      immediate: true,
    },
  },
};
