import Loading from '@@/core/components/shared/Loading/Loading'
import marketersModal from '@@/core/components/marketers/marketersForm/marketersForm'
import {BTooltip, BTable, VBModal,BFormInput} from "bootstrap-vue";

import mixins from '@@/core/mixins/mixins'

export default {
  name: "marketers",
  components: {
    Loading,
    marketersModal,
    BTooltip, BTable,BFormInput
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [mixins],
  data() {
    return {
      searchList:[],
      search:null,
      Items:null,
      url: 'admin/marketers',
      fields: [
        {
          key: 'id',
          label: 'شناسه'
        },
        {
          key: 'name',
          label: 'نام'
        },
        {
          key: 'last_name',
          label: 'نام خانوادگی'
        },
        {
          key: 'mobile',
          label: 'شماره '
        },
        {
          key: 'status',
          label: 'وضعیت'

        },
        
        {
          key: 'edit',
          label: 'ویرایش'
        },
        {
          key: 'delete',
          label: 'حذف'
        },
      ],
      submit_url: 'admin/marketers',
      mode: 'create',
      marketer: {
        name: '',
        last_name: '',
        mobile: '',
        status: 1,
        index:0,
      },
      select_type: null,
    }
  },
  mounted() {
    this.loadItems().then((res)=>{
      this.Items=res.data.data.marketers
    })
  },
  methods: {
    async editItem(id, index) {
      this.disabled = true
      try {
        this.marketer = this.Items.find((item) => {
          return item.id == id;
        })
        this.mode = 'edit'
        this.submit_url = this.url + `/${id}`
        this.$root.$emit('bv::show::modal', 'marketers-modal')
        this.marketer.index = index
      } catch (error) {
        this.$root.notify(error)
      } finally {
        this.disabled = false
      }
    },
    newmarketers() {
      this.mode = 'create'
      this.submit_url = this.url
      this.marketer = {
        name: '',
        last_name: '',
        mobile: '',
        status: 1,
      }
    },
    searchRequest() {
      if(this.search==null||this.search==''){
        this.extraQuery=null;
        this.loadItems();
      }else{
        this.extraQuery={keyword:this.search}
        this.loadItems()
      }
    },
    DeleteItem(id) {
      window
        .swal({
          title: "آیتم حذف شود؟",
          text: "این عملیات غیر قابل بازگشت خواهد بود",
          icon: "warning",

          showCancelButton: true,
          showConfirmButton: true,
          dangerMode: true,
          buttons: ["خیر", "بله حذف کن"],
        })
        .then((willDelete) => {
          if (willDelete) {
            const url = this.url.includes("?")
              ? this.url.substr(0, this.url.indexOf("?"))
              : this.url;
            this.$axios
              .delete(url + `/${id}`)
              .then((response) => {
                  this.Items = this.Items.filter(
                    (item) => item.id != id
                  );
                window.swal({
                  icon: "success",
                  showConfirmButton: true,
                  text: response.data.message,
                  button: "باشه",
                });
              })
              .catch((error) => {
                window.swal({
                  title: "خطا",
                  showConfirmButton: true,
                  text: error.response.data.message,
                  button: "باشه",
                });
              });
          }
        })
        .catch(() => {});
    },
  },
  
}
